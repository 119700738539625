import React from "react";

class NavBarAbout extends React.Component {
  render() {
    return (
      <a href="#about" className="nav-link">
        Lore
      </a>
    );
  }
}

export default NavBarAbout;
