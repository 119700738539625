import React from "react";
import WhitepaperSectionHeader from "./WhitepaperSectionHeader";
import WhitepaperSectionBody from "./WhitepaperSectionBody";

class WhitepaperSection extends React.Component {
  render() {
    return (
      <div id="whitepaper" className="landing-body-content">
        <WhitepaperSectionHeader></WhitepaperSectionHeader>
        <WhitepaperSectionBody></WhitepaperSectionBody>
      </div>
    );
  }
}

export default WhitepaperSection;
