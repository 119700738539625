import React, {useState} from "react";

const FaqQuestion = (props) => {
  const {question, answer} = props;
  const [expanded, setExpanded] = useState(false);

  // Split answer by <br/> tags
  const answerSplit = answer.split("<br/>");

  // Create a new array of <p> tags with the answerSplit array
  const answerArray = answerSplit.map((answer, index) => {
    return <p key={index}>{answer}</p>;
  });

  // Add a span tag with the class span-rainbow to any words that are inside curly braces
  answerArray.forEach((answer, index) => {
    if (answer.props.children.includes("{")) {
      const answerSplit = answer.props.children.split("{");
      const answerSplit2 = answerSplit[1].split("}");
      answerArray[index] = (
        <p key={index}>
          {answerSplit[0]}
          <span className="span-rainbow">{answerSplit2[0]}</span>
          {answerSplit2[1]}
        </p>
      );
    }
  });

  // Add a link tag to any words that are inside square brackets
  answerArray.forEach((answer, index) => {
    if (answer.props.children.includes("[")) {
      const answerSplit = answer.props.children.split("[");
      const answerSplit2 = answerSplit[1].split("]");
      answerArray[index] = (
        <p key={index}>
          {answerSplit[0]}
          <a
            href="https://www.youtube.com/watch?v=dQw4w9WgXcQ&ab_channel=RickAstley"
            target="_blank"
            rel="noopener noreferrer"
            className="faq-section-body-content-answer-link"
          >
            {answerSplit2[0]}
          </a>
          {answerSplit2[1]}
        </p>
      );
    }
  });

  return (
    <div className="faq-section-body-content">
      <button className="faq-section-body-content-question" onClick={() => setExpanded(!expanded)}>
        {question}
      </button>
      {expanded && <div className="faq-section-body-content-answer landing-body-content-body">{answerArray}</div>}
    </div>
  );
};

export default FaqQuestion;
