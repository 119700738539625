import React from "react";
import MintSectionHeader from "./MintSectionHeader";
import MintSectionBody from "./MintSectionBody";

class MintSection extends React.Component {
  render() {
    return (
      <div id="mint" className="landing-body-content">
        <MintSectionHeader></MintSectionHeader>
        <MintSectionBody></MintSectionBody>
      </div>
    );
  }
}

export default MintSection;
