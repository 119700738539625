import {ReactSVG} from "react-svg";
import Marquee from "react-fast-marquee";
import RenderIfVisible from "react-render-if-visible";

// Make this component accept the props from LandingBody.js
const SVGCarousel = (props) => {
  return (
    <div className="carousel">
      <Marquee
        gradient={true}
        gradientColor={[40, 44, 52]}
        gradientWidth={200}
        speed={props.carouselAttributes.speed}
        direction={props.carouselAttributes.direction}
        className="carousel"
      >
        {/* For each image in images, create a ReactSVG component */}
        {props.images.map((image) => (
          <ReactSVG src={image} className="carousel-item" />
        ))}
      </Marquee>
    </div>
  );
};

export {SVGCarousel};
