import React from "react";

class NavBarFaq extends React.Component {
  render() {
    return (
      <a href="#faq" className="nav-link">
        FAQ
      </a>
    );
  }
}

export default NavBarFaq;
