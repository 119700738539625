import React from "react";
import NavBar from "../../../../global/nav/NavBar";
import Links from "./Links";

class CitizensOfOverworldHeaderText extends React.Component {
  render() {
    return (
      <div className="landing-head">
        <h1 className="landing-head-title">Citizens of Overworld</h1>
        <Links />
        <NavBar />
        <p className="landing-head-description">
          100% on-chain Animated Digital Collectibles
          <br />
          <br /> Randomly generated on the Ethereum blockchain
          <br />
          <br />
          <span className="span-rainbow">Create because you can</span>
        </p>
      </div>
    );
  }
}

export default CitizensOfOverworldHeaderText;
