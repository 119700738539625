import React from "react";
import AboutSectionHeader from "./AboutSectionHeader";
import AboutSectionBody from "./AboutSectionBody";

class AboutSection extends React.Component {
  render() {
    return (
      <div id="about" className="landing-body-content">
        <AboutSectionHeader></AboutSectionHeader>
        <AboutSectionBody></AboutSectionBody>
      </div>
    );
  }
}

export default AboutSection;
