import React from "react";
import {ReactComponent as Twitter} from "../../../../global/twitter.svg";
import {ReactComponent as Medium} from "../../../../global/medium.svg";
import {ReactComponent as Discord} from "../../../../global/discord.svg";
import {ReactComponent as Etherscan} from "../../../../global/etherscan.svg";
import twitter from "../../../../global/twitter.svg";
import medium from "../../../../global/medium.svg";
import discord from "../../../../global/discord.svg";
import etherscan from "../../../../global/etherscan.svg";
import marketplace from "../../../../global/marketplace.png";
import contract from "../../../../global/contract";

class Links extends React.Component {
  render() {
    return (
      <div className="landing-head-content-links">
        <a href="https://twitter.com/overworldxyz" target="_blank" rel="noreferrer">
          <Twitter fill="white" className="social-link" src={twitter} />
        </a>
        <a href="https://medium.com/@0xMongoon" target="_blank" rel="noreferrer">
          <Medium fill="white" className="social-link" src={medium} />
        </a>
        <a href="https://discord.gg/nmXHtxqMjH" target="_blank" rel="noreferrer">
          <Discord fill="white" className="social-link" src={discord} />
        </a>
        <a href={`https://etherscan.io/address/${contract.address}`} target="_blank" rel="noreferrer">
          <Etherscan fill="white" className="social-link" src={etherscan} />
        </a>
        <a href={`https://opensea.io/collection/citizens-of-overworld`} target="_blank" rel="noreferrer">
          <img className="social-link" src={marketplace} />
        </a>{" "}
      </div>
    );
  }
}

export default Links;
