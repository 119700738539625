import React from "react";
import WalletConnect from "./WalletConnect";
import WalletConnectPlaceholder from "./WalletConnectPlaceholder";

class MintSectionBody extends React.Component {
  render() {
    return <WalletConnectPlaceholder />;
  }
}

export default MintSectionBody;
