import React from "react";
import CitizensOfOverworldHeaderDiv from "./head-content/CitizensOfOverworldHeaderDiv";
const gifRecorder = `${process.env.PUBLIC_URL}/GifRecorderHtml.html`;
const degenTranslator = `${process.env.PUBLIC_URL}/DegenTranslator.html`;
const spritesheetViewer = `${process.env.PUBLIC_URL}/SpritesheetViewer.html`;

class LandingHead extends React.Component {
  render() {
    return (
      <div className="landing-head">
        <a href={gifRecorder}> GIF Recorder </a>|<a href={spritesheetViewer}> Spritesheets </a>|<a href={degenTranslator}> translator</a>
        <CitizensOfOverworldHeaderDiv />
      </div>
    );
  }
}

export default LandingHead;
