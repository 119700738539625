import React from "react";

class NavBarMint extends React.Component {
  render() {
    return (
      <a href="#mint" className="nav-link">
        Claim
      </a>
    );
  }
}

export default NavBarMint;
