import React from "react";
import FaqQuestion from "./FaqQuestion";

class FaqSectionBody extends React.Component {
  render() {
    return (
      <div className="faq-section-body">
        <FaqQuestion question="What are these?" answer="Citizens of Overworld are {Digital Collectibles} hosted on the Ethereum blockchain."></FaqQuestion>
        <FaqQuestion
          question="What is the purpose of this collection?"
          answer="The goal was to create a {Digital Collectible} that is guided by the following principles:<br/>{On-Chain}<br/>{Free}<br/>{Visually Appealing}<br/>{Personable}<br/>The hope is that this will inspire others to {create} things in this space that hold these principles in high regard."
        ></FaqQuestion>
        <FaqQuestion
          question="What are Digital Collectibles?"
          answer="Digital Collectibles are digital assets (made up of data, 1's and 0's) that are stored on the blockchain (in this case, Ethereum).<br/>They can be traded, bought, and sold with Ethereum."
        ></FaqQuestion>
        <FaqQuestion
          question="What does 100% on-chain mean?"
          answer="This means that Citizens and their data are stored on the blockchain.<br/>For Citizens of Overworld, there is a single smart contract that contains the data and functions for claiming, generating, rendering, and trading Citizens.<br/>With no third-party hosting or middlemen outside of the smart contract on the Ethereum blockchain, the Digital Collectibles are considered to be 100% on-chain."
        ></FaqQuestion>
        <FaqQuestion
          question="How do I claim a Citizen?"
          answer="You can claim a Citizen by clicking the Claim button on the homepage. You will be prompted to connect your Metamask wallet and then you will be able to claim a Citizen!"
        ></FaqQuestion>
        <FaqQuestion
          question="Traits & Rarity?"
          answer="Traits are pseudorandomly generated upon claiming (not 100% random due to blockchain limitations, but random enough for our purposes)<br/>Some fun facts about the traits:<br/>{6 billion} possible DNA combinations<br/>{153} pixel-perfect traits<br/>{0.32%} chance to mint a specially-animaated legendary citizen<br/>Each trait has a possibility of being {animated}! (except for background and mouth traits)"
        ></FaqQuestion>
        <FaqQuestion
          question="How much does it cost to claim a Citizen?"
          answer="Your first claim is {FREE}.<br/>You will only need to pay for gas fees when you claim a Citizen.<br/>If you wish to claim more than one, each claim after the first is {0.005 ETH}.<br/>We originally planned only 1 claim per wallet, but 1 seemed too low.<br/>So we increased it to 4 claims per wallet, and decided to charge a small fee after the first mint, with the logic that smaller mint amount per wallet = more holders."
        ></FaqQuestion>
        <FaqQuestion
          question="What is a gas fee?"
          answer="Gas is the fee that is paid to the Ethereum network for a transaction to be processed.<br/>Gas fees are paid in Ethereum (ETH)."
        ></FaqQuestion>
        <FaqQuestion
          question="Licensing?"
          answer="Citizens of Overworld is licensed under the {Creative Commons Zero v1.0 Universal License}.<br/>This means that you can use Citizens of Overworld for any purpose, including commercial purposes, without asking permission."
        ></FaqQuestion>
        <FaqQuestion
          question="What is the value of a Citizen? Will it go up in value?"
          answer="Citizens of Overworld are not an investment.<br/>These Digital Collectibles hold no promises or expectations whatsoever of value increase.<br/>If you expect/intend to make money from Citizens, please do not claim any -- your motivations are not condoned by this project."
        ></FaqQuestion>
        <FaqQuestion question="I'm an investor/venture capitalist. How can I invest?" answer="Investors and VCs please [see this link]."></FaqQuestion>
        <FaqQuestion
          question="Who made these?"
          answer="This collection was developed by {0xMongooon} (see Team section at the bottom of this page), with inspiration from other on-chain, free, and CC0 collections."
        ></FaqQuestion>
      </div>
    );
  }
}

export default FaqSectionBody;
