import React from "react";
import FaqSectionHeader from "./FaqSectionHeader";
import FaqSectionBody from "./FaqSectionBody";

class FaqSection extends React.Component {
  render() {
    return (
      <div id="faq" className="landing-body-content">
        <FaqSectionHeader></FaqSectionHeader>
        <FaqSectionBody></FaqSectionBody>
      </div>
    );
  }
}

export default FaqSection;
