import React from "react";
import LandingHead from "./head/LandingHead";
import LandingBody from "./body/LandingBody";
import NavBar from "../../global/nav/NavBar";

class Landing extends React.Component {
  render() {
    return (
      <div id="landing">
        <LandingHead />
        <LandingBody />
      </div>
    );
  }
}

export default Landing;
