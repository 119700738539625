import React from "react";

class NavBarTeam extends React.Component {
  render() {
    return (
      <a href="#team" className="nav-link">
        Team
      </a>
    );
  }
}

export default NavBarTeam;
