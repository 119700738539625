import React from "react";

class AboutSectionBody extends React.Component {
  render() {
    return (
      <p className="landing-body-content-body">
        The <span className="span-rainbow">Overworld</span> is a tucked away location within the Ethereum blockchain where all of the Citizens of Overworld
        live. <br />
        <br />
        In the Overworld, there is an intense desire to create new things--
        <br />
        <br />
        <br />
        Wonderful things.
        <br />
        <br />
        Terrible things.
        <br />
        <br />
        Things that will change the world.
        <br />
        <br />
        Things that will fade into obscurity forever.
        <br />
        <br />
        <br />
        Citizens spend every single one of their precious days creating new things, and when they are done, they share them with the world.
        <br />
        <br />
        <br />
        <br />
        Why?
        <br />
        <br />
        <br />
        <br />
        Because they can.
      </p>
    );
  }
}

export default AboutSectionBody;
