import React from "react";
import pfp from "./mongoon_pfp1.png";

class TeamSectionBody extends React.Component {
  render() {
    return (
      <div className="landing-body-content-body">
        <div className="landing-body-content-body-team">
          <a href="https://twitter.com/0xmongoon">
            <img className="landing-body-content-body-team-image" src={pfp} />
            <p className="landing-body-content-body-team-name">0xMongoon</p>
            <p className="landing-body-content-body-team-bio">
              Developer. On-chain enthusiast. Retired Club Penguin hacker. Thinks web3 VC's should get a job.
              <br />
              <br />
              Twitter @0xMongoon
            </p>
          </a>
        </div>

        <br />
        <br />
        <br />
        <p className="landing-body-content-body">Inspired by the on-chain projects and small-time creators that came before.</p>
      </div>
    );
  }
}

export default TeamSectionBody;
