import React from "react";

class NavBarHome extends React.Component {
  render() {
    return (
      <a href="#citizens-of-overworld" className="nav-link">
        Home
      </a>
    );
  }
}

export default NavBarHome;
