import "./App.css";
import Landing from "./components/pages/landing/Landing";
import {BrowserRouter as Router, Routes, Route, Redirect, Link} from "react-router-dom";

function App() {
  {
  }
  console.log("app");
  return (
    <div className="App">
      <Landing />
    </div>
  );
}

export default App;
