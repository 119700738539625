import React from "react";
import MintSection from "./mint/MintSection";
import AboutSection from "./about/AboutSection";
import TeamSection from "./team/TeamSection";
import {SVGCarousel} from "./carousel/SVGCarousel";
import FaqSection from "./faq/FaqSection";
import WhitepaperSection from "./whitepaper/WhitepaperSection";

class LandingBody extends React.Component {
  render() {
    function importAll(r) {
      let images = {};
      r.keys().map((item, index) => {
        images[item.replace("./", "")] = r(item);
      });
      return images;
    }
    const imagesObject1 = importAll(require.context("./carousel/img/group1", false, /\.(png|jpe?g|svg)$/));
    const images1 = Object.values(imagesObject1);

    const imagesObject2 = importAll(require.context("./carousel/img/group2", false, /\.(png|jpe?g|svg)$/));
    const images2 = Object.values(imagesObject2);

    const imagesObject3 = importAll(require.context("./carousel/img/group3", false, /\.(png|jpe?g|svg)$/));
    const images3 = Object.values(imagesObject3);

    const imagesObject4 = importAll(require.context("./carousel/img/group4", false, /\.(png|jpe?g|svg)$/));
    const images4 = Object.values(imagesObject4);

    const imagesObject5 = importAll(require.context("./carousel/img/group5", false, /\.(png|jpe?g|svg)$/));
    const images5 = Object.values(imagesObject5);

    const carouselAttributes1 = {
      speed: 2,
      direction: "left",
    };
    const carouselAttributes2 = {
      speed: 2,
      direction: "right",
    };
    const carouselAttributes3 = {
      speed: 2,
      direction: "left",
    };
    const carouselAttributes4 = {
      speed: 2,
      direction: "right",
    };
    const carouselAttributes5 = {
      speed: 2,
      direction: "left",
    };
    return (
      <div className="landing-body">
        <SVGCarousel images={images1} carouselAttributes={carouselAttributes1} />

        <MintSection></MintSection>

        {/* <SVGCarousel images={images2} carouselAttributes={carouselAttributes2} /> */}

        <AboutSection></AboutSection>

        {/* <SVGCarousel images={images3} carouselAttributes={carouselAttributes3} /> */}

        <WhitepaperSection></WhitepaperSection>

        {/* <SVGCarousel images={images4} carouselAttributes={carouselAttributes4} /> */}

        <FaqSection></FaqSection>

        {/* <SVGCarousel images={images5} carouselAttributes={carouselAttributes5} /> */}

        <TeamSection></TeamSection>
        <div>
          <br />
          <br />
          <br />
        </div>
      </div>
    );
  }
}

export default LandingBody;
