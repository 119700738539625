import React from "react";
import {useState} from "react";
import {ReactComponent as LoadingImage} from "../../../../global/loading-citizen.svg";
import loadingImage from "../../../../global/loading-citizen.svg";

const WhitepaperSectionBody = () => {
  const [e, setE] = useState(false);

  const showE = () => {
    // pop up a modal with the easter egg
    setE(!e);
  };

  return (
    <div className="landing-body-content-body">
      <p>
        <span className="span-rainbow">Citizens of Overworld</span> is intended to provide the web3 community with a simple, authentic, & palatable digital
        collectible.
      </p>
      <br />
      <p>To achieve this, it was made sure that there would be no facade tokenomics, no third-party hosting, & no noisy art--</p>
      <br />
      Just 1 smart contract and some clean-looking, ani
      <span id="e-link" onClick={showE}>
        m
      </span>
      ated collectibles.
      <br />
      <br />
      <p>
        Oh, and they're <span className="span-rainbow">free</span>.
      </p>
      {e && (
        <div id="e">
          <p>
            <LoadingImage src={loadingImage} />
            <br />
            <br />
            <span className="span-rainbow">Congratuations fren.</span>
            <br />
            <br />
            Please DM{" "}
            <a href="https://twitter.com/overworldxyz" target={"_blank"}>
              {" "}
              @overworldxyz{" "}
            </a>{" "}
            on Twitter to claim something special for finding this ⚡️
            <br />
            <br />
            (only first user will get the prize)
          </p>
        </div>
      )}
    </div>
  );
};

export default WhitepaperSectionBody;
