import React from "react";

class NavBarRarity extends React.Component {
  render() {
    return (
      <a href="#whitepaper" className="nav-link">
        Whitepaper
      </a>
    );
  }
}

export default NavBarRarity;
