import React from "react";

class WalletConnectPlaceholder extends React.Component {
  render() {
    return (
      <div className="landing-body-content-body">
        <button className="button-connect-wallet">Minting complete</button>
        <br />
        <br />
        <br />
        <div style={{fontSize: "xx-large"}}>
          <span className="span-rainbow">11,080</span> Citizens were claimed
          <br />
          in under 1 hour
        </div>
        <br />
        <br />
        <div style={{fontSize: "xx-large"}}>
          We welcome you to visit the <span className="span-rainbow">Overworld</span> on a secondary marketplace
        </div>
        <br />
        <a href="https://opensea.io/collection/citizens-of-overworld" target={"_blank"} style={{fontSize: "xx-large"}}>
          OpenSea
        </a>
        <br />
        <br />
        <a href="https://blur.io/collection/citizens-of-overworld" target={"_blank"} style={{fontSize: "xx-large"}}>
          Blur
        </a>
        <br />
        <br />
        <a href="https://x2y2.io/collection/citizens-of-overworld/items" target={"_blank"} style={{fontSize: "xx-large"}}>
          x2y2
        </a>
        <br />
        <br />
        <a href="https://rarible.com/collection/0x2ACDFE510384c08e62556dA121eD81242DaB7aAd" target={"_blank"} style={{fontSize: "xx-large"}}>
          Rarible
        </a>
        <br />
        <br />
        <a href="https://looksrare.org/collections/0x2ACDFE510384c08e62556dA121eD81242DaB7aAd" target={"_blank"} style={{fontSize: "xx-large"}}>
          Looksrare
        </a>
      </div>
    );
  }
}

export default WalletConnectPlaceholder;
