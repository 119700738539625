import React from "react";
import NavBarHome from "./NavBarHome";
import NavBarMint from "./NavBarMint";
import NavBarAbout from "./NavBarAbout";
import NavBarTeam from "./NavBarTeam";
import NavBarFaq from "./NavBarFaq";
import NavBarWhitepaper from "./NavBarWhitepaper";

class NavBar extends React.Component {
  render() {
    window.addEventListener("load", () => {
      let scrollDirection = "down";

      const navbar = document.querySelector(".navbar");

      window.addEventListener("scroll", () => {
        if (window.scrollY > 50) {
          navbar.classList.add("hidden");
        } else if (window.scrollY < 50) {
          navbar.classList.remove("hidden");
        }
      });
    });
    return (
      <div className="navbar">
        <NavBarHome></NavBarHome>
        <NavBarMint></NavBarMint>
        <NavBarAbout></NavBarAbout>
        <NavBarWhitepaper></NavBarWhitepaper>
        <NavBarFaq></NavBarFaq>
        <NavBarTeam></NavBarTeam>
      </div>
    );
  }
}

export default NavBar;
