import React from "react";
import TeamSectionHeader from "./TeamSectionHeader";
import TeamSectionBody from "./TeamSectionBody";

class TeamSection extends React.Component {
  render() {
    return (
      <div id="team" className="landing-body-content">
        <TeamSectionHeader></TeamSectionHeader>
        <TeamSectionBody></TeamSectionBody>
      </div>
    );
  }
}

export default TeamSection;
