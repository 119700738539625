import React from "react";
import CitizensOfOverworldHeaderText from "./CitizensOfOverworldHeaderText";

class CitizensOfOverworldHeaderDiv extends React.Component {
  render() {
    return (
      <div id="citizens-of-overworld">
        <CitizensOfOverworldHeaderText></CitizensOfOverworldHeaderText>
      </div>
    );
  }
}

export default CitizensOfOverworldHeaderDiv;
